import { Medication, MedicationJson } from "./medication";

export interface StagedOrderJson {
  member_id: string;
  medication: MedicationJson;
  sourcing_fee: number;
  warning_message: string;
  quantity: number;
}

export class StagedOrder {
  constructor(
    public medication: Medication,
    public memberId: string,
    public sourcingFee: number,
    public warningMessage: string,
    public quantity: number
  ) {}

  toJson() {
    return {
      medication_id: this.medication.id,
      member_id: this.memberId,
      quantity: this.quantity,
    };
  }

  static fromJson(data: StagedOrderJson): StagedOrder {
    return new StagedOrder(
      Medication.fromJson(data.medication),
      data.member_id,
      data.sourcing_fee,
      data.warning_message,
      data.quantity
    );
  }
}

export interface OrderJson {
  id: number;
  member_id: string;
  status: string;
  created_at: string;
  medication: MedicationJson;
  sourcing_fee: number;
  tracking_number: string;
}

export class Order {
  constructor(
    public id: number,
    public medication: Medication,
    public memberId: string,
    public status: string,
    public createdAt: Date,
    public sourcingFee: number,
    public trackingNumber: string
  ) {}

  static fromJson(data: OrderJson): Order {
    return new Order(
      data.id,
      Medication.fromJson(data.medication),
      data.member_id,
      data.status,
      new Date(data.created_at),
      data.sourcing_fee,
      data.tracking_number
    );
  }
}
