export interface MedicationJson {
  id: number;
  display_name: string;
  generic_name: string;
  strength: string;
  sourcing_fee: number;
  ndc: string;
  awp: number;
}

export class Medication {
  constructor(
    public id: number,
    public displayName: string,
    public genericName: string,
    public strength: string,
    public ndc: string,
    public sourcingFee: number,
    public awp: number
  ) {}

  static fromJson(data: MedicationJson): Medication {
    return new Medication(
      data.id,
      data.display_name,
      data.generic_name,
      data.strength,
      data.ndc,
      data.sourcing_fee,
      data.awp
    );
  }
}
