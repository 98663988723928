import { ReactNode, createContext, useContext, useState } from "react";
import { StagedOrder } from "../types/order";

interface StagedOrdersValue {
  stagedOrders: StagedOrder[] | undefined;
  setStagedOrders: (stagedOrders: StagedOrder[] | undefined) => void;
}

const StagedOrdersContext = createContext<StagedOrdersValue>(
  {} as StagedOrdersValue
);

export default function StagedOrdersProvider(props: { children: ReactNode }) {
  const [stagedOrders, setStagedOrders] = useState<StagedOrder[] | undefined>(
    undefined
  );

  const handleSetStagedOrders = (stagedOrders: StagedOrder[] | undefined) => {
    setStagedOrders(stagedOrders);
  };

  const value = {
    stagedOrders: stagedOrders,
    setStagedOrders: handleSetStagedOrders,
  };

  return (
    <StagedOrdersContext.Provider value={value}>
      {props.children}
    </StagedOrdersContext.Provider>
  );
}

export const useStagedOrders = () => {
  const context = useContext(StagedOrdersContext);
  if (!context) {
    throw new Error(
      "useStagedOrders must be used within a StagedOrdersProvider"
    );
  }
  return context;
};
