import React, { ChangeEvent, DragEvent, useState } from "react";
import styles from "./styles.module.css";

interface FileUploadProps {
  onUpload: (file: File) => void;
  accept?: string;
}

const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {
  const [dragging, setDragging] = useState(false);

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length > 0) {
      const droppedFile = droppedFiles[0];
      props.onUpload(droppedFile);
    }
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    if (selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      props.onUpload(selectedFile);
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput")?.click();
  };

  return (
    <div
      className={`${styles.dropzone} ${dragging ? styles.active : ""}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleFileSelect}
        accept={props.accept}
      />
      <div className={styles.fileUploadContent}>
        <img
          className={styles.uploadIcon}
          src={require("../../../assets/Upload.svg").default}
          alt="MediCircle Logo"
        ></img>
        <p className={styles.fileUploadText}>Drag and drop a claims file or</p>
        <button className={styles.uploadButton}>Browse Files</button>
        {props.accept && (
          <p className={styles.acceptTypes}>*Files supported: {props.accept}</p>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
