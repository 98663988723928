import { Auth0Provider } from "@auth0/auth0-react";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { BrowserRouter as Router } from "react-router-dom";
import styles from "./App.module.css";
import MyRoutes from "./components/MyRoutes/MyRoutes";
import Sidebar from "./components/Sidebar/Sidebar";
import MyToastContainer from "./components/Toast/MyToastContainer";
import AuthUserProvider from "./providers/AuthUserProvider";
import GlobalOverlayProvider from "./providers/GlobalOverlayProvider";
import StagedOrdersProvider from "./providers/StagedOrdersProvider";

function App() {
  useEffect(() => {
    document.title = "MediCircle";
  }, []);

  return isMobile ? (
    <div className={styles.mobile}>Please view on a computer.</div>
  ) : (
    <Router>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ""}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://medicircle/api",
          scope: "profile email read:inventory read:orders",
        }}
      >
        <AuthUserProvider>
          <StagedOrdersProvider>
            <GlobalOverlayProvider>
              <div className={styles.app}>
                <Sidebar />
                <div className={styles.content}>
                  <MyRoutes />
                </div>
              </div>
            </GlobalOverlayProvider>
          </StagedOrdersProvider>
        </AuthUserProvider>
      </Auth0Provider>

      <MyToastContainer />
    </Router>
  );
}

export default App;
