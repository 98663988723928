import { useAuth0 } from "@auth0/auth0-react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { submitOrder } from "../../../api/orders_network_api";
import Button from "../../../components/Button/Button";
import { Medication } from "../../../types/medication";
import styles from "./styles.module.css";

interface MedicationDetailProps {
  medication: Medication;
  onExit: (created: boolean) => void;
}

interface FormInput {
  memberId: string;
  quantity: number;
}

export default function MedicationDetail(props: MedicationDetailProps) {
  const { getAccessTokenSilently } = useAuth0();
  const notifyError = (text: string) => toast.error(text);

  const methods = useForm<FormInput>({ mode: "onSubmit" });

  const onSubmit = async (data: FormInput) => {
    const token = await getAccessTokenSilently();
    return submitOrder(token, data.quantity, data.memberId, props.medication.id)
      .then(() => {
        props.onExit(true);
      })
      .catch(() => {
        notifyError("Error placing order");
      });
  };

  return (
    <div className={styles.detail}>
      <FormProvider {...methods}>
        <form className={styles.card} onSubmit={methods.handleSubmit(onSubmit)}>
          <button
            type="button"
            className={styles.exit}
            onClick={() => props.onExit(false)}
          >
            <img src={require("../../../assets/X.svg").default} alt="exit" />
          </button>
          <div className={styles.content}>
            <div className={styles.data}>
              <p className={styles.name}>
                {props.medication.displayName} {props.medication.strength}
              </p>
              <div className={styles.metadata}>
                <p className={styles.genericName}>
                  {props.medication.genericName}
                </p>
                <p className={styles.genericName}>
                  NDC: {props.medication.ndc}
                </p>
                <p className={styles.genericName}>
                  Sourcing Fee:{" "}
                  <span className={styles.sourcingFee}>
                    ${props.medication.sourcingFee}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className={styles.inputs}>
            <div>
              <p className={styles.label}>Member ID</p>
              <input
                {...methods.register("memberId", {
                  required: true,
                })}
                placeholder="Member ID"
                className={styles.input}
                style={{ marginBottom: "16px" }}
              ></input>
            </div>
            <div>
              <p className={styles.label}>Quantity</p>
              <input
                {...methods.register("quantity", {
                  required: true,
                })}
                min={0}
                placeholder="Quantity"
                className={`${styles.input} ${
                  methods.formState.errors.quantity ? styles.dueDateError : ""
                }`}
              ></input>
            </div>
          </div>

          {!methods.formState.isValid && methods.formState.isSubmitted && (
            <p className={styles.error}>Please correct errors</p>
          )}

          <div className={styles.buttonWrap}>
            <Button submit={true} pill={false} primary={true} width="100%">
              Place Order
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
