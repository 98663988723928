import { Navigate, Route, Routes } from "react-router";
import Analytics from "../../pages/Analytics";
import AnalyzeClaims from "../../pages/AnalyzeClaims";
import ContactSupport from "../../pages/ContactSupport/ContactSupport";
import Faq from "../../pages/Faq/Faq";
import { default as Dashboard } from "../../pages/Inventory/Inventory";
import Landing from "../../pages/Landing";
import OrderHistory from "../../pages/OrderHistory/OrderHistory";
import ProtectedRoute from "./ProtectedRoute";

export default function MyRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Landing></Landing>}></Route>

      <Route path="/analyze" element={<ProtectedRoute />}>
        <Route
          path="/analyze"
          element={<AnalyzeClaims></AnalyzeClaims>}
        ></Route>
      </Route>

      <Route path="/analytics" element={<ProtectedRoute />}>
        <Route path="/analytics" element={<Analytics></Analytics>}></Route>
      </Route>

      <Route path="/inventory" element={<ProtectedRoute />}>
        <Route path="/inventory" element={<Dashboard></Dashboard>}></Route>
      </Route>
      <Route path="/orders" element={<ProtectedRoute />}>
        <Route path="/orders" element={<OrderHistory></OrderHistory>}></Route>
      </Route>
      <Route path="/faq" element={<ProtectedRoute />}>
        <Route path="/faq" element={<Faq></Faq>}></Route>
      </Route>
      <Route path="/support" element={<ProtectedRoute />}>
        <Route
          path="/support"
          element={<ContactSupport></ContactSupport>}
        ></Route>
      </Route>
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/" element={<AnalyzeClaims></AnalyzeClaims>}></Route>
      </Route>
      <Route path="*" element={<ProtectedRoute />}>
        <Route path="*" element={<Navigate to="/analyze"></Navigate>}></Route>
      </Route>
    </Routes>
  );
}
