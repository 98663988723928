import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";

import AnalyticsPlaceholderCard from "./AnalyticsPlaceholderCard";
import styles from "./styles.module.css";

export default function Analytics() {
  return (
    <DashboardPageBody title="Analytics">
      <div className={styles.pageContent}>
        <p className={styles.subtitle}>
          This page is not available in demo mode. Register for the full version
          to gain access to savings insights and statistics.
        </p>
        <div className={styles.placeholderGrid}>
          <AnalyticsPlaceholderCard />
          <AnalyticsPlaceholderCard />
          <AnalyticsPlaceholderCard />
          <AnalyticsPlaceholderCard />
          <AnalyticsPlaceholderCard />
          <AnalyticsPlaceholderCard />
          <AnalyticsPlaceholderCard />

          <AnalyticsPlaceholderCard />
        </div>
      </div>
    </DashboardPageBody>
  );
}
