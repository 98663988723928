import Button from "../../Button/Button";
import styles from "./DashboardStates.module.css";

interface DashboardPageEmptyStateProps {
  title: string;
  subtitle?: string;
  actionLabel?: React.ReactNode;
  onClickAction?: () => void;
}

export default function DashboardPageEmptyState(
  props: DashboardPageEmptyStateProps
) {
  return (
    <div className={styles.emptyState}>
      <h3 className={styles.emptyStateTitle}>{props.title}</h3>
      <p className={styles.emptyStateSubtitle}>{props.subtitle}</p>

      {props.actionLabel && (
        <Button onClick={props.onClickAction} pill={false}>
          {props.actionLabel}
        </Button>
      )}
    </div>
  );
}
