import MoonLoader from "react-spinners/PropagateLoader";
import styles from "./DashboardStates.module.css";

export default function DashboardPageLoadState() {
  return (
    <div className={styles.loadState}>
      <MoonLoader
        color={"var(--color-primary)"}
        speedMultiplier={0.75}
      ></MoonLoader>
    </div>
  );
}
