import instance from "./http_client";

export function submitSupportRequest(
  token: string,
  title: string,
  content: string
) {
  return instance.post(
    "/support/new/",
    { title: title, content: content },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}
