import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { submitOrders } from "../../../api/orders_network_api";
import WarningIcon from "../../../assets/Warning.svg";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import DashboardPageBody from "../../../components/DashboardPageBody/DashboardPageBody";
import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";
import { useStagedOrders } from "../../../providers/StagedOrdersProvider";
import { ApiStatus } from "../../../types/api_status";
import { StagedOrder } from "../../../types/order";
import { numberWithCommas } from "../../../utils";
import styles from "./styles.module.css";

interface ConfirmMatchesProps {
  stagedOrders: StagedOrder[];
  onCancel: () => void;
}

export default function ConfirmMatches(props: ConfirmMatchesProps) {
  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
  const { setStagedOrders } = useStagedOrders();

  const [submissionStatus, setSubmissionStatus] = useState<ApiStatus>(
    ApiStatus.INITIAL
  );

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const notifySuccess = (text: string) => toast.success(text);
  const notifyError = (text: string) => toast.error(text);

  const [rows, setRows] = useState(
    props.stagedOrders.map((value, index) => ({
      id: index,
      checked: !value.warningMessage,
      order: value,
    }))
  );

  const handleCheckboxChange = (id: number) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, checked: !row.checked } : row
      )
    );
  };

  const handleSubmit = async () => {
    setSubmissionStatus(ApiStatus.LOADING);
    const token = await getAccessTokenSilently();

    const selectedOrders = rows
      .filter((row) => row.checked)
      .map((r) => r.order);

    try {
      await submitOrders(token, selectedOrders);
      setSubmissionStatus(ApiStatus.SUCCESS);
      if (selectedOrders.length > 1) {
        notifySuccess("Orders placed");
      } else {
        notifySuccess("Order placed");
      }
      setStagedOrders(undefined);
      navigate("/orders");
    } catch (e) {
      setSubmissionStatus(ApiStatus.FAILURE);
      notifyError(`Error: Orders placed ${e}`);
    }
  };

  return (
    <DashboardPageBody title="Place Orders">
      <div className={styles.pageContent}>
        <p
          className={styles.subtitle}
          style={
            props.stagedOrders.length === 0 ? { marginBottom: 0 } : undefined
          }
        >
          {props.stagedOrders.length === 0
            ? "Unfortunately we could not match any claims to our available inventory."
            : `We found ${props.stagedOrders.length} match${
                props.stagedOrders.length > 1 ? "es" : ""
              } with our inventory. Confirm your order below.`}
        </p>
      </div>
      <div>
        {props.stagedOrders.length !== 0 ? (
          <>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th></th>
                  <th>Member ID</th>
                  <th>Medication</th>
                  {/* <th>NDC</th> */}
                  <th>List Price</th>
                  <th>Sourcing Fee</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row.id}>
                    <td>
                      <div className={styles.checkBoxCell}>
                        <input
                          type="checkbox"
                          checked={row.checked}
                          onChange={() => handleCheckboxChange(row.id)}
                        />
                      </div>
                    </td>
                    <td>{row.order.memberId}</td>
                    <td>
                      {row.order.medication.displayName}{" "}
                      {row.order.medication.strength}
                    </td>
                    {/* <td>{row.ndc}</td> */}
                    <td>${numberWithCommas(row.order.medication.awp)}</td>
                    <td>${numberWithCommas(row.order.sourcingFee)}</td>{" "}
                    <td className={`${styles.noPadding} ${styles.warningBox}`}>
                      {row.order.warningMessage && (
                        <button
                          onClick={() =>
                            setGlobalOverlay(
                              <ConfirmationDialog
                                title={"Warning"}
                                hideLeft
                                subtitle={row.order.warningMessage}
                                onConfirm={hideGlobalOverlay}
                              ></ConfirmationDialog>
                            )
                          }
                        >
                          <img
                            className={styles.warningIcon}
                            src={WarningIcon}
                            alt={"Warning"}
                          ></img>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={styles.actions}>
              <button onClick={props.onCancel} className={styles.cancelButton}>
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className={styles.submitButton}
                disabled={submissionStatus === ApiStatus.LOADING}
              >
                <div
                  className={styles.loaderBox}
                  style={{ position: "relative" }}
                >
                  <p
                    style={{
                      visibility:
                        submissionStatus === ApiStatus.LOADING
                          ? "hidden"
                          : "visible",
                    }}
                  >
                    Submit Selected Orders
                  </p>
                  <BeatLoader
                    className={styles.loader}
                    color="white"
                    style={{
                      position: "absolute",
                      inset: "0",

                      visibility:
                        submissionStatus === ApiStatus.LOADING
                          ? "visible"
                          : "hidden",
                    }}
                    size={10}
                  ></BeatLoader>
                </div>
              </button>
            </div>
          </>
        ) : (
          <button onClick={props.onCancel} className={styles.submitButton}>
            Return
          </button>
        )}
      </div>
    </DashboardPageBody>
  );
}
