import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getInventory } from "../../api/inventory_network_api";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import DashboardPageEmptyState from "../../components/DashboardPageBody/DashboardStates/DashboardPageEmptyState";
import DashboardPageFailState from "../../components/DashboardPageBody/DashboardStates/DashboardPageFailState";
import DashboardPageLoadState from "../../components/DashboardPageBody/DashboardStates/DashboardPageLoadState";
import DashboardPageNoResults from "../../components/DashboardPageBody/DashboardStates/DashboardPageNoResults";
import Search from "../../components/Search/Search";
import { ApiStatus } from "../../types/api_status";
import { Medication } from "../../types/medication";
import styles from "./Inventory.module.css";
import InventoryList from "./InventoryList/InventoryList";

interface InventoryState {
  filter: string;
  medications: Medication[] | null;
  medicationsApiStatus: ApiStatus;
}

const filterMedications = (filter: string, medications: Medication[]) => {
  if (filter === "") {
    return medications;
  }

  const pattern = filter.toUpperCase();

  return medications.filter(
    (m) =>
      `${m.displayName} ${m.strength}`.toUpperCase().includes(pattern) ||
      m.ndc.includes(pattern)
  );
};

export default function Inventory() {
  const { getAccessTokenSilently } = useAuth0();

  const [state, setState] = useState<InventoryState>({
    filter: "",
    medications: null,
    medicationsApiStatus: ApiStatus.LOADING,
  });

  const setFilter = (filter: string) => {
    setState((prev) => ({ ...prev, filter }));
  };

  useEffect(() => {
    const _getInventory = async () => {
      const token = await getAccessTokenSilently();

      if (token) {
        try {
          const inventory = await getInventory(token);
          setState((prev) => ({
            ...prev,
            medications: inventory,
            medicationsApiStatus: ApiStatus.SUCCESS,
          }));
        } catch {
          setState((prev) => ({
            ...prev,
            medicationsApiStatus: ApiStatus.FAILURE,
          }));
        }
      }
    };
    _getInventory();
  }, [getAccessTokenSilently]);

  return (
    <DashboardPageBody title="Inventory">
      <div className={styles.body}>
        <p className={styles.subtitle}>
          Looking for a specific medication? Browse our repository of reclaimed
          medications that are ready to be dispensed to patients.
        </p>
        <div className={styles.suffix}>
          <Search
            width="100%"
            padding="14px 16px"
            placeholder="Search available medications"
            onChange={setFilter}
            borderRadius="500px"
            fontSize="16px"
            autofocus={true}
          ></Search>
        </div>
        {state.medications !== null ? (
          <div className={styles.content}>
            {filterMedications(state.filter, state.medications).length === 0 ? (
              state.filter.length > 0 ? (
                <DashboardPageNoResults
                  query={state.filter}
                ></DashboardPageNoResults>
              ) : (
                <DashboardPageEmptyState
                  title={"Nothing to display right now"}
                  subtitle="Please check again later."
                ></DashboardPageEmptyState>
              )
            ) : (
              <InventoryList
                medications={filterMedications(state.filter, state.medications)}
              ></InventoryList>
            )}
          </div>
        ) : state.medicationsApiStatus === ApiStatus.FAILURE ? (
          <DashboardPageFailState
            errorMessage="Unable to load inventory"
            subtitle="Please try reloading."
          ></DashboardPageFailState>
        ) : (
          <DashboardPageLoadState></DashboardPageLoadState>
        )}
      </div>
    </DashboardPageBody>
  );
}
