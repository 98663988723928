import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { toast } from "react-toastify";
import { cancelOrder } from "../../../api/orders_network_api";
import Card from "../../../components/Card/Card";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";
import { Order } from "../../../types/order";
import { getPrettyDate, numberWithCommas, round } from "../../../utils";
import styles from "./OrderHistoryList.module.css";

interface OrdereHistoryItemProps {
  order: Order;
  onViewConsentCode: () => void;
}

export default function OrderHistoryItem(props: OrdereHistoryItemProps) {
  const [order, setOrder] = useState<Order>(props.order);

  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
  const { getAccessTokenSilently } = useAuth0();

  const notifySuccess = (text: string) => toast.success(text);
  const notifyError = (text: string) => toast.error(text);

  const cancel = () => {
    return getAccessTokenSilently().then((token) =>
      cancelOrder(token, props.order.id)
        .then(() => {
          notifySuccess("Order sucessfully canceled");
          setOrder({ ...order, status: "Canceled" });
        })
        .catch(() => {
          notifyError("Unable to cancel order");
        })
    );
  };

  return (
    <Card className={styles.orderHistoryItem}>
      <div className={styles.header}>
        <div>
          <h3 className={styles.status}>Status: {order.status}</h3>
          {/* {props.order.trackingNumber && ( */}
          <p className={styles.trackingNumber}>
            Tracking number:{" "}
            {props.order.trackingNumber ? props.order.trackingNumber : "N/A"}
          </p>
          {/* )} */}
        </div>
        {order.status !== "Canceled" && (
          <button
            onClick={() =>
              setGlobalOverlay(
                <ConfirmationDialog
                  title={"Are you sure you want to cancel this order?"}
                  leftLabel="Go Back"
                  isDestructive={true}
                  onConfirm={() => {
                    hideGlobalOverlay();
                    cancel();
                  }}
                  onCancel={hideGlobalOverlay}
                ></ConfirmationDialog>
              )
            }
            className={styles.cancel}
          >
            Cancel order
          </button>
        )}
      </div>

      <div className={styles.data}>
        <div className={styles.dataPoint}>
          <p className={styles.label}>Member ID</p>
          <p className={styles.value}>{order.memberId}</p>
        </div>
        <div className={styles.dataPoint}>
          <p className={styles.label}>Order placed</p>
          <p className={styles.value}>{getPrettyDate(order.createdAt)}</p>
        </div>
        <div className={styles.dataPoint}>
          <p className={styles.label}>Medication</p>
          <div className={styles.orderItems}>
            {order.medication.displayName}
          </div>
        </div>
        <div className={styles.dataPoint}>
          <p className={styles.label}>Sourcing fee</p>
          <p className={styles.value}>
            ${numberWithCommas(round(order.sourcingFee, 2))}
          </p>
        </div>
      </div>
    </Card>
  );
}
