import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getOrders } from "../../api/orders_network_api";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import DashboardPageEmptyState from "../../components/DashboardPageBody/DashboardStates/DashboardPageEmptyState";
import DashboardPageFailState from "../../components/DashboardPageBody/DashboardStates/DashboardPageFailState";
import DashboardPageLoadState from "../../components/DashboardPageBody/DashboardStates/DashboardPageLoadState";
import Search from "../../components/Search/Search";
import { ApiStatus } from "../../types/api_status";
import { Order } from "../../types/order";
import OrderHistoryItem from "./OrderHistoryList/OrderHistoryItem";

interface OrderHistoryState {
  status: ApiStatus;
  orders: Order[] | null;
  filter: string;
}

const filterOrders = (filter: string, orders: Order[]) => {
  const pattern = filter.toUpperCase();
  return orders.filter(
    (o) =>
      o.memberId.toUpperCase().includes(pattern) ||
      o.medication.displayName.toUpperCase().includes(pattern) ||
      o.trackingNumber.toUpperCase().includes(pattern)
  );
};

export default function OrderHistory() {
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const [state, setState] = useState<OrderHistoryState>({
    status: ApiStatus.INITIAL,
    orders: null,
    filter: "",
  });

  useEffect(() => {
    getAccessTokenSilently().then((token) =>
      getOrders(token)
        .then((orders) => {
          setState((prev) => ({
            ...prev,
            orders: orders,
            status: ApiStatus.SUCCESS,
          }));
        })
        .catch((e) => {
          setState((prev) => ({
            ...prev,
            status: ApiStatus.FAILURE,
          }));
        })
    );
  }, [getAccessTokenSilently]);

  return (
    <DashboardPageBody
      title="Order History"
      headerSuffix={
        <Search
          placeholder="Search orders"
          onChange={(val) => setState((prev) => ({ ...prev, filter: val }))}
        ></Search>
      }
    >
      <div style={{ marginBottom: 8 }}></div>
      {state.orders !== null ? (
        state.orders.length > 0 ? (
          <>
            {filterOrders(state.filter, state.orders).map((order, index) => (
              <OrderHistoryItem
                key={order.id}
                onViewConsentCode={() =>
                  setState((prev) => ({
                    ...prev,
                    displayedOrderConsentInfo: order,
                  }))
                }
                order={order}
              ></OrderHistoryItem>
            ))}
          </>
        ) : (
          <DashboardPageEmptyState
            title="You haven't made any orders"
            subtitle="Get started by browsing our inventory."
            actionLabel="Go to inventory"
            onClickAction={() => navigate("/inventory")}
          ></DashboardPageEmptyState>
        )
      ) : state.status === ApiStatus.FAILURE ? (
        <DashboardPageFailState
          errorMessage="Unable to get order history"
          subtitle="Please try reloading."
        />
      ) : (
        <DashboardPageLoadState></DashboardPageLoadState>
      )}
    </DashboardPageBody>
  );
}
