import { Link } from "react-router-dom";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import styles from "./Faq.module.css";
import FaqItem from "./FaqItem";

export default function Faq() {
  return (
    <DashboardPageBody title={"FAQ"}>
      <p className={styles.subtitle}>
        If you have further questions, we're here to help. Please do not
        hesitate to reach out. <Link to="/support">Contact Support</Link>
      </p>
      <FaqItem title="How does MediCircle work?">
        <>
          <p>
            MediCircle reclaims brand-new, unopened prescriptions, recertifies
            their quality, and then distributes them to patients for massive
            savings.
          </p>
          <p>
            MediCircle works with hospitals, health systems, and specialty
            centers to reclaim brand-new, unopened, unexpired, medications that
            are still sealed in the manufacturer’s original packaging. Our
            pharmacists then use our patented recertification process to ensure
            the medication’s quality. We dispense the medications directly to
            the patient's door, at no cost to the patient.
          </p>
        </>
      </FaqItem>
      <FaqItem title="How much does MediCircle cost?">
        <p>
          MediCircle does not participate in the resale of medications or submit
          claims for reimbursement. The resale of medications is strictly
          prohibited.
        </p>
        <p>
          Instead, MediCircle charges Service Fees for the added value that
          MediCircle provides patients, payers, providers, and health systems.
          MediCircle’s Service Fees cover the cost of procuring and recertifying
          the medication.
        </p>
      </FaqItem>
      <FaqItem title="What are the tiers?">
        <>
          <p>
            MediCircle categorizes each dose of a medication into a tier based
            on the amount of resources necessary to procure the supply for a
            prescription. The tiers are determined by how difficult a medication
            is to source and the demand for the medication by patients,
            providers, and health systems.
          </p>
          <p>Fees associated with each tier:</p>
          <ul>
            <li>Bronze: $100 </li>
            <li>Silver: $1000</li>
            <li>Gold: $3000</li>
            <li>Platinum: $6000</li>
          </ul>
        </>
      </FaqItem>
      <FaqItem title="How much do members pay?">
        <p>
          Members receive their medication for free with MediCircle. Members do
          not pay for shipping.
        </p>
      </FaqItem>
    </DashboardPageBody>
  );
}
