import LoaderIndicator from "../../components/Loader/indicator";
import styles from "./AppLoading.module.css";

export default function AppLoading() {
  return (
    <div className={styles.appLoading}>
      <LoaderIndicator loadingText="Loading..."></LoaderIndicator>
    </div>
  );
}
