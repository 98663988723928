import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";
import { Medication } from "../../../types/medication";
import MedicationDetail from "../MedicationDetail";
import styles from "./InventoryList.module.css";

interface InventoryListItemProps {
  medication: Medication;
  index: number;
}

export default function InventoryListItem(props: InventoryListItemProps) {
  const notify = () =>
    toast.success(
      <p>
        Order placed{" "}
        <span className={styles.link}>
          <Link to="/orders">View Orders</Link>
        </span>
      </p>
    );

  const m = props.medication;

  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const showDetail = () => {
    setGlobalOverlay(
      <MedicationDetail
        medication={m}
        onExit={(cartItemCreated: boolean) => {
          hideGlobalOverlay();
          if (cartItemCreated) {
            notify();
          }
        }}
      ></MedicationDetail>
    );
  };

  return (
    <div
      className={`${styles.inventoryListItem} ${
        props.index % 2 === 0 ? styles.shaded : ""
      }`}
    >
      <div className={styles.nameWrap}>
        <p className={styles.name} onClick={showDetail}>
          {m.displayName}{" "}
          <span className={styles.strength}>
            {" "}
            {m.strength} ({m.ndc})
          </span>
        </p>
      </div>
      <p className={styles.sourcingFee}>
        ${props.medication.sourcingFee} sourcing fee
      </p>
      <div className={styles.buttonWrap}>
        <button className={styles.selectButton} onClick={showDetail}>
          Select
        </button>
      </div>
    </div>
  );
}
