import { Order, StagedOrder } from "../types/order";
import instance from "./http_client";

export function getOrders(token: string) {
  return instance
    .get("/sandbox/orders", { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => response.data.map(Order.fromJson));
}

export function cancelOrder(token: string, pk: number) {
  console.log(token);
  return instance.post(`/sandbox/orders/${pk}/cancel`, undefined, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function submitOrders(token: string, orders: StagedOrder[]) {
  return instance.post(
    "/sandbox/upload-orders",
    orders.map((o) => o.toJson()),
    { headers: { Authorization: `Bearer ${token}` } }
  );
}

export function submitOrder(
  token: string,
  quantity: number,
  memberId: string,
  medicationId: number
) {
  return instance.post(
    "/sandbox/orders/new",
    {
      medication_id: medicationId,
      member_id: memberId,
      quantity: quantity,
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
}

export function submitRefillOrder(prescriptionPk: number) {
  return instance.post(`/orders/refills/${prescriptionPk}/new`);
}
