import ContentLoader from "react-content-loader";
import styles from "./styles.module.css";

export default function AnalyticsPlaceholderCard() {
  return (
    <div className={styles.card}>
      <ContentLoader
        speed={2}
        width={"100%"}
        height={"100%"}
        viewBox="0 0 400 200"
        foregroundColor="#d4deed"
        backgroundColor="#e2e9f3"
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="20" />
        <rect x="0" y="45" rx="5" ry="5" width="100%" height="20" />
        <rect x="0" y="90" rx="5" ry="5" width="75%" height="20" />
        <rect x="0" y="135" rx="5" ry="5" width="60%" height="20" />
        <rect x="0" y="180" rx="5" ry="5" width="25%" height="20" />
      </ContentLoader>
    </div>
  );
}
