import { useState } from "react";
import ChevronDown from "../../assets/ChevronDown.svg";
import styles from "./Faq.module.css";

export default function FaqItem(props: {
  title: string;
  children: React.ReactNode;
}) {
  const [expanded, setExpanded] = useState(true);

  return (
    <div className={styles.faqItem}>
      <button
        className={`${styles.header} ${expanded ? styles.headerExpanded : ""}`}
        onClick={() => setExpanded(!expanded)}
      >
        <h2>{props.title}</h2>
        <img
          src={ChevronDown}
          alt="Expand"
          className={`${styles.chevron} ${expanded ? styles.flip : ""}`}
        ></img>
      </button>
      {expanded && <div className={styles.answer}>{props.children}</div>}
    </div>
  );
}
