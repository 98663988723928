import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router";
import styles from "./Sidebar.module.css";
import SidebarFooter from "./SidebarFooter";
import SidebarNavItem from "./SidebarNav/SidebarNavItem";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const { isAuthenticated } = useAuth0();

  const path = location.pathname;

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className={styles.sidebar}>
      <div>
        <img
          className={styles.logo}
          src={require("../../assets/LogoDark.svg").default}
          alt="MediCircle Logo"
        ></img>
        <div className={styles.nav}>
          <SidebarNavItem
            label="Analyze claims"
            onClick={() => navigate("/analyze")}
            active={path === "/analyze" || path === "/"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="Inventory"
            onClick={() => navigate("/inventory")}
            active={path === "/inventory"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="Orders"
            onClick={() => navigate("/orders")}
            active={path === "/orders"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="Analytics"
            onClick={() => navigate("/analytics")}
            active={path === "/analytics"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="FAQ"
            onClick={() => navigate("/faq")}
            active={path === "/faq"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="Contact us"
            onClick={() => navigate("/support")}
            active={path === "/support"}
          ></SidebarNavItem>
        </div>
      </div>
      <SidebarFooter></SidebarFooter>
    </div>
  );
}
