import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { analyzeClaims } from "../../api/claim_analysis_api";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import LoaderIndicator from "../../components/Loader/indicator";
import { useStagedOrders } from "../../providers/StagedOrdersProvider";
import ConfirmMatches from "./ConfirmMatches";
import FileUpload from "./FileUpload/FileUpload";
import styles from "./styles.module.css";

export default function AnalyzeClaims() {
  const [analyzing, setAnalyzing] = useState(false);

  const { stagedOrders, setStagedOrders } = useStagedOrders();

  const { getAccessTokenSilently } = useAuth0();

  const handlePost = async (file: File) => {
    setAnalyzing(true);

    const token = await getAccessTokenSilently();

    try {
      const results = await analyzeClaims(token, file);
      setTimeout(() => {
        setStagedOrders(results);
        setAnalyzing(false);
      }, 3000);
    } catch (e) {
      setAnalyzing(false);
      alert("Error analyzing claims");
    }
  };

  return stagedOrders ? (
    <ConfirmMatches
      onCancel={() => setStagedOrders(undefined)}
      stagedOrders={stagedOrders}
    ></ConfirmMatches>
  ) : (
    <DashboardPageBody title="Analyze Claims">
      <div className={styles.pageContent}>
        <p className={styles.subtitle}>
          Upload your claims data to automatically find medication matches.
          Simply upload your file, <br></br>review the matched items, and
          confirm your order.
        </p>
        <div className={styles.panel}>
          {analyzing ? (
            <LoaderIndicator loadingText="This may take a few moments..."></LoaderIndicator>
          ) : (
            <FileUpload onUpload={handlePost} accept=".xlsx"></FileUpload>
          )}
        </div>
      </div>
    </DashboardPageBody>
  );
}
