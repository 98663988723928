import { StagedOrder, StagedOrderJson } from "../types/order";
import instance from "./http_client";

export function analyzeClaims(
  token: string,
  claimsFile: File
): Promise<StagedOrder[]> {
  const formData = new FormData();
  formData.append("file", claimsFile);
  return instance
    .post("/sandbox/analyze_claims", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) =>
      response.data.map((json: StagedOrderJson) => StagedOrder.fromJson(json))
    );
}
